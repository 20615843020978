<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card :loading="isLoading" :disabled="isLoading">

        <div class="d-flex align-center justify-space-between pa-4">
          <h4>
            Envoyer une demande de paiement
          </h4>
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </div>

        <v-divider/>

        <v-card-text class="pa-8">


          <v-alert color="warning" icon="mdi-alert" v-if="!code">
            Alert: Si la fonctionnalité ne fonctionne pas, veuillez demander à l'utilisateur de mettre à jour
            l'application.
          </v-alert>

          <v-row v-if="!code">

            <v-col cols="12">
              <v-text-field dense
                            :error-messages="errors.phone"
                            hide-details="auto"
                            prepend-inner-icon="mdi-phone"
                            label="Numéro de téléphone *"
                            placeholder="Ex: 0550000000"
                            outlined
                            :counter="10"
                            persistent-hint
                            v-model="form.phone_0">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-select dense
                        :items="services"
                        :error-messages="errors.service_id"
                        hide-details="auto"
                        item-text="name"
                        item-value="id"
                        label="Service *"
                        outlined
                        v-model="form.service_id">
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field dense
                            type="number"
                            :error-messages="errors.amount"
                            label="Montant *"
                            hide-details="auto"
                            outlined
                            v-model="form.amount">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <div class="text-end">
                <v-btn
                    :disabled="!form.service_id || !form.amount"
                    :loading="btnLoading"
                    color="gifty"
                    class="rounded-lg white--text"
                    depressed
                    @click="generateOnlinePayment">
                  <v-icon left>mdi-check</v-icon>
                  Valider
                </v-btn>
              </div>
            </v-col>

          </v-row>


          <div v-if="!success && code">
            <div class="text-center rounded-lg">

              <p class="font-weight-medium">
                Numéro de l'utilisateur : {{ form.phone }}
              </p>

              <h1 class="gifty--text mt-5">
                {{ CurrencyFormatting(form.amount) }} DZD
              </h1>

              <div class="mt-10 bg-light pa-8 rounded-lg">
                <div v-if="start">
                  <h3 class="font-weight-bold mb-3">
                    Expire dans
                  </h3>
                  <v-progress-circular color="gifty"
                                       size="110"
                                       width="8"
                                       :value="timeLeft * 100 / allSeconds">
                    <h2>
                      {{ formatTimeLeft(timeLeft) }}
                    </h2>
                  </v-progress-circular>
                </div>
                <div v-else>
                  <p class="font-weight-medium mb-2">
                    Le paiement a expiré. Veuillez recommencer le processus.
                  </p>
                  <v-icon size="80" color="red">
                    mdi-alert-circle
                  </v-icon>
                </div>
              </div>


              <div class="d-flex mt-10 justify-space-between">
                <v-spacer/>
                <v-btn color="primary" depressed dark class="rounded-lg" @click="code = null">
                  <v-icon left>mdi-arrow-left</v-icon>
                  Retour
                </v-btn>
              </div>

            </div>
          </div>


          <div v-if="success && code" class="text-center">

            <lottie :options="defaultOptions"
                    v-if="!is_refused"
                    :height="200"
                    :width="200"/>

            <div v-if="is_refused">
              <v-icon size="80" color="red">mdi-cancel</v-icon>
            </div>

            <h1 class="pb-5 gifty--text" v-if="!is_refused">
              Paiement accepté !
            </h1>

            <h1 class="py-5 red--text" v-if="is_refused">
              Paiement refusé.
            </h1>

            <p v-if="!is_refused">
              Paiement accepté avec succès.
            </p>

            <div class="text-center mt-5" v-if="!is_refused && consumedVoucher">
              <v-btn color="gifty"
                     dark
                     text
                     class="v-btn--active"
                     depressed
                     @click="$refs.printContent.printPdf(consumedVoucher)">
                <v-icon left>mdi-cloud-print-outline</v-icon>
                Imprimer
              </v-btn>
            </div>

            <p v-if="is_refused">
              Cette paiement a été refusé.
            </p>

            <div class="d-flex mt-10 justify-space-between">
              <v-btn color="red" text dark class="rounded-lg" @click="close">
                <v-icon left>mdi-close</v-icon>
                Fermer
              </v-btn>
              <v-spacer/>
              <v-btn color="gifty" depressed dark class="rounded-lg" @click="open">
                <v-icon left>mdi-plus</v-icon>
                Nouveau
              </v-btn>
            </div>

          </div>


        </v-card-text>

      </v-card>
    </v-dialog>

    <PrintContent ref="printContent"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import lottie from 'vue-lottie';
import successIcon from '@/assets/success-lottie.json';
import PrintContent from "@/views/partner_vouchers/components/PrintContent";

export default {
  components: {
    lottie,
    PrintContent,
  },
  data() {
    return {
      isLoading: false,
      btnLoading: false,
      dialog: false,
      services: [],
      form: {
        phone_0: null
      },
      errors: {},
      code: null,
      success: false,
      is_refused: false,
      defaultOptions: {
        animationData: successIcon,
        loop: false,
        autoplay: true,
      },

      expiredAt: null,
      timer: null,
      timeLeft: null,
      start: false,
      allSeconds: 0,
      partner_payment_id: null,
      consumedVoucher: {},
    }
  },
  methods: {
    formatTimeLeft(seconds) {
      const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
      const secs = (seconds % 60).toString().padStart(2, '0');
      return `${minutes}:${secs}`;
    },
    updateCountdown() {
      const expirationTime = new Date(this.expiredAt);
      const now = new Date();
      this.timeLeft = Math.max(0, Math.floor((expirationTime - now) / 1000));
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.updateCountdown();
        if (this.timeLeft <= 0) {
          clearInterval(this.timer);
          this.start = false
        }
      }, 1000);
    },
    open() {
      this.partner_payment_id = null;
      this.is_refused = false;
      this.success = false;
      this.dialog = true;
      this.reset();
      if (!this.services.length) {
        this.getServices();
      }
    },
    reset() {
      this.form = {
        store_id: this.$route.params.id,
        amount: null,
        phone_0: null
      };
      this.errors = {};
      this.code = null;
      this.start = false
      this.expiredAt = null;
      this.timer = null;
      this.timeLeft = null;

      if (this.services && this.services.length) {
        this.form.service_id = this.services[0]['id']
      } else {
        this.form.service_id = null
      }
    },
    close() {
      this.dialog = false;
      this.partner_payment_id = null;
    },
    generateOnlinePayment() {
      this.code = null;
      this.btnLoading = true;
      this.start = false
      this.errors = {};

      let data = this.form
      data['phone'] = this.form?.phone_0?.replace(/^0/, '+213');

      HTTP.post('/v1/partner-vouchers/add-online-payment', data)
          .then(res => {
            this.btnLoading = false;
            this.partner_payment_id = res.data.data.id;
            this.code = res.data.data.code;
            this.expiredAt = res.data.data.expired_at;

            this.start = true
            const expirationTime = new Date(this.expiredAt);
            const now = new Date();
            this.allSeconds = Math.max(0, Math.floor((expirationTime - now) / 1000));

            this.startTimer();
          })
          .catch(err => {
            this.btnLoading = false;
            this.errors = err.response.data.errors;
            console.error(err);
          });
    },
    getServices() {
      this.isLoading = true;
      HTTP.get('/v1/partner-vouchers/services')
          .then(res => {
            this.isLoading = false;
            this.services = res.data.data;
            if (this.services && this.services.length) {
              this.form.service_id = this.services[0]['id']
            }
          })
          .catch(err => {
            this.isLoading = false;
            console.error(err);
          });
    }
  },
  mounted() {
    let storeId = this.$route.params.id

    window.Echo.channel('consume-channel.' + storeId)
        .listen('.consume.event', (res) => {
          if (res && res.consumedVoucher && res.partner_payment_id === this.partner_payment_id) {
            this.consumedVoucher = res.consumedVoucher
            this.success = true
            this.$emit('success-payment', res.consumedVoucher)
          }
        });

    window.Echo.channel('refuse-online-payment-channel.' + storeId)
        .listen('.refuse.event', (res) => {
          if (res && res.partner_payment_id === this.partner_payment_id) {
            this.success = true
            this.is_refused = true
            this.$errorMessage = "Cette paiement a été refusé."
          }
        });
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
}
</script>
<style>
</style>