<template>
  <div>
    <v-dialog v-model="dialog" width="600" persistent>
      <v-card :loading="isLoading" :disabled="isLoading">

        <div class="d-flex align-center justify-space-between pa-4">
           <h4>
             Générer un code QR de paiement
           </h4>
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </div>

        <v-divider/>

        <v-card-text class="pa-8">


          <v-alert color="warning" icon="mdi-alert" v-if="!code">
            Alert: Si la fonctionnalité ne fonctionne pas, veuillez demander à l'utilisateur de mettre à jour
            l'application.
          </v-alert>

          <v-row v-if="!code">

            <v-col cols="12">
              <v-select dense
                        :items="services"
                        :error-messages="errors.service_id"
                        hide-details="auto"
                        item-text="name"
                        item-value="id"
                        label="Service *"
                        outlined
                        v-model="form.service_id">
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field dense
                            type="number"
                            :error-messages="errors.amount"
                            label="Montant *"
                            hide-details="auto"
                            outlined
                            v-model="form.amount">
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <div class="text-end">
                <v-btn
                    :disabled="!form.service_id || !form.amount"
                    :loading="btnLoading"
                    color="gifty"
                    class="rounded-lg white--text"
                    depressed
                    @click="generateQrCode">
                  Générer un code qr
                </v-btn>
              </div>
            </v-col>

          </v-row>


          <div v-if="!success">
            <div class="text-center bg-primary-subtle rounded-lg"
                 v-if="code">
              <qrcode-vue
                  :size="250"
                  :value="code"
                  background="transparent"
                  foreground="#d60c56"/>

              <h3 class="mt-5 font-weight-medium">
                Scanner ce QR code pour payer
              </h3>

              <h1 class="gifty--text mt-5">
                {{ CurrencyFormatting(form.amount) }} DZD
              </h1>

              <div class="d-flex mt-10 justify-space-between">
                <v-spacer/>
                <v-btn color="primary" depressed dark class="rounded-lg" @click="code = null">
                  <v-icon left>mdi-arrow-left</v-icon>
                  Retour
                </v-btn>
              </div>

            </div>
          </div>


          <div v-if="success && code" class="text-center">
            <lottie :options="defaultOptions" :height="200" :width="200"/>

            <h1 class="pb-5 gifty--text">
              Succès !
            </h1>

            <p>
              Paiement effectué avec succès.
            </p>

            <div class="text-center mt-5">
              <v-btn color="gifty"
                     dark
                     text
                     class="v-btn--active"
                     depressed
                     @click="$refs.printContent.printPdf(consumedVoucher)">
                <v-icon left>mdi-cloud-print-outline</v-icon>
                Imprimer
              </v-btn>
            </div>

            <div class="d-flex mt-10 justify-space-between">
              <v-btn color="red" text dark class="rounded-lg" @click="close">
                <v-icon left>mdi-close</v-icon>
                Fermer
              </v-btn>
              <v-spacer/>
              <v-btn color="gifty" depressed dark class="rounded-lg" @click="open">
                <v-icon left>mdi-plus</v-icon>
                Nouveau
              </v-btn>
            </div>

          </div>


        </v-card-text>

      </v-card>
    </v-dialog>

    <PrintContent ref="printContent"/>

  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import {HTTP} from "@/http-common";
import lottie from 'vue-lottie';
import successIcon from '@/assets/success-lottie.json';
import PrintContent from "@/views/partner_vouchers/components/PrintContent";

export default {
  components: {
    QrcodeVue,
    PrintContent,
    lottie,
  },
  data() {
    return {
      isLoading: false,
      btnLoading: false,
      dialog: false,
      services: [],
      form: {},
      errors: {},
      code: null,
      success: false,
      defaultOptions: {
        animationData: successIcon,
        loop: false,
        autoplay: true,
      },
      partner_payment_id: null,
      consumedVoucher: {},
    }
  },
  methods: {
    open() {
      this.partner_payment_id = null;
      this.success = false;
      this.dialog = true;
      this.reset();
      if (!this.services.length) {
        this.getServices();
      }
    },
    reset() {
      this.form = {
        store_id: this.$route.params.id,
        amount: null,
      };
      this.errors = {};
      this.code = null;

      if (this.services && this.services.length) {
        this.form.service_id = this.services[0]['id']
      } else {
        this.form.service_id = null
      }
    },
    close() {
      this.dialog = false;
      this.partner_payment_id = null;
    },
    generateQrCode() {
      this.errors = {};
      this.btnLoading = true;
      this.code = null;
      HTTP.post('/v1/partner-vouchers/add-payment', this.form)
          .then(res => {
            this.btnLoading = false;
            this.partner_payment_id = res.data.data.id;
            this.code = res.data.data.code;
          })
          .catch(err => {
            this.btnLoading = false;
            this.errors = err.response.data.errors;
            console.error(err);
          });
    },
    getServices() {
      this.isLoading = true;
      HTTP.get('/v1/partner-vouchers/services')
          .then(res => {
            this.isLoading = false;
            this.services = res.data.data;
            if (this.services && this.services.length) {
              this.form.service_id = this.services[0]['id']
            }
          })
          .catch(err => {
            this.isLoading = false;
            console.error(err);
          });
    }
  },
  mounted() {
    let storeId = this.$route.params.id
    window.Echo.channel('consume-channel.' + storeId)
        .listen('.consume.event', (res) => {
          if (res && res.consumedVoucher && res.partner_payment_id === this.partner_payment_id) {
            this.success = true
            this.consumedVoucher = res.consumedVoucher
            this.$emit('success-payment', res.consumedVoucher)
          }
        });

  }
}
</script>
<style>
</style>