<template>
  <div>
    <v-row>

      <v-col cols="6" lg="3">
        <v-card flat class="rounded-lg" color="gifty" dark>
          <v-card-text>
            <v-row>
              <v-col>
                <h3 class="mb-2 white--text">DZD</h3>
                <v-skeleton-loader v-if="loading" type="text"/>
                <span v-else class="white--text font-weight-medium">
                  {{ CurrencyFormatting(statistics.current_day) }}
                </span>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="white" size="45">
                  <v-icon color="gifty">mdi-calendar-today</v-icon>
                </v-avatar>
              </v-col>
            </v-row>

            <div class="d-flex justify-space-between align-center mt-3">
              <span class="white--text text-no-wrap">Aujourd'hui</span>
              <v-spacer/>
              <strong>
                <v-icon dense @click="$emit('is-today',true)">mdi-arrow-right</v-icon>
              </strong>
            </div>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" lg="3">
        <v-card class="shadow rounded-lg" :loading="monthLoading">
          <v-card-text>
            <v-row>
              <v-col>
                <h3 class="mb-2">DZD</h3>
                <v-skeleton-loader v-if="loading" type="text"/>
                <span class="font-weight-medium" v-else>
                  {{ CurrencyFormatting(statistics.current_week) }}
                </span>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="primary" size="45">
                  <v-icon dark>mdi-calendar-week</v-icon>
                </v-avatar>
              </v-col>
            </v-row>

            <div class="d-flex justify-space-between align-center mt-3">
              <span class="text-no-wrap">Cette semaine</span>
            </div>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" lg="3">
        <v-card class="shadow rounded-lg" :loading="monthLoading">
          <v-card-text>
            <v-row>
              <v-col>
                <h3 class="mb-2">DZD</h3>
                <v-skeleton-loader v-if="loading || monthLoading" type="text"/>
                <span class="font-weight-medium" v-else>
                  {{ CurrencyFormatting(statistics.current_month) }}
                </span>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="gifty" size="45">
                  <v-icon dark>mdi-calendar-month</v-icon>
                </v-avatar>
              </v-col>
            </v-row>

            <div class="d-flex justify-space-between align-center mt-3">
              <span class="text-no-wrap">Ce mois</span>
              <v-spacer/>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="30"/>
                <v-menu v-else
                        transition="slide-x-transition"
                        bottom right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex font-weight-medium gifty--text">
                      {{
                        statistics.this_month ? months.find(el => el.value === parseInt(statistics.this_month)).name.slice(0, 3) : ''
                      }}
                      <v-icon dense color="gifty">mdi-chevron-down</v-icon>
                    </span>
                  </template>

                  <v-list-item-group v-model="statistics.this_month"
                                     active-class="primary white--text">
                    <v-list>
                      <v-list-item v-for="(month, i) in months"
                                   @click="getVouchersAmountByMonth(month.value)"
                                   :value="month.value"
                                   :key="i">
                        <v-list-item-title>{{ month.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-list-item-group>
                </v-menu>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6" lg="3">
        <v-card class="rounded-lg shadow" :loading="yearLoading">
          <v-card-text>
            <v-row>
              <v-col>
                <h3 class="mb-2">DZD</h3>
                <v-skeleton-loader v-if="loading || yearLoading" type="text"/>
                <span class="font-weight-medium" v-else>
                  {{ CurrencyFormatting(statistics.current_year) }}
                </span>
              </v-col>
              <v-col class="text-right">
                <v-avatar color="primary" size="45">
                  <v-icon dark>mdi-calendar-check</v-icon>
                </v-avatar>
              </v-col>
            </v-row>

            <div class="d-flex justify-space-between align-center mt-3">
              <span class="text-no-wrap">Cette année</span>
              <v-spacer/>
              <div>
                <v-skeleton-loader v-if="loading" type="text" width="30"/>
                <v-menu v-else
                        transition="slide-x-transition"
                        bottom right
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="d-flex primary--text font-weight-medium">
                      {{ statistics.this_year ? (statistics.this_year.toString()).slice(2, 4) : '' }}
                      <v-icon dense color="primary">mdi-chevron-down</v-icon>
                    </span>
                  </template>

                  <v-list-item-group v-model="statistics.this_year"
                                     active-class="primary white--text">
                    <v-list>
                      <v-list-item v-for="(year) in years"
                                   :value="year"
                                   @click="getVouchersAmountByYear(year)"
                                   :key="year">
                        <v-list-item-title>{{ year }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-list-item-group>
                </v-menu>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  props: ['statistics', 'loading', 'storeId'],
  data() {
    return {
      monthLoading: false,
      yearLoading: false,
    }
  },
  methods: {
    getVouchersAmountByMonth(month) {
      this.monthLoading = true
      let data = {
        store_id: this.storeId,
        month: month,
      }
      HTTP.get('/v1/partner-vouchers/amount-month', {
        params: data
      }).then(res => {
        this.monthLoading = false
        this.statistics.current_month = res.data.data.amount
      }).catch(err => {
        this.monthLoading = false
        console.log(err)
      })
    },
    getVouchersAmountByYear(year) {
      this.yearLoading = true
      let data = {
        store_id: this.storeId,
        year: year,
      }
      HTTP.get('/v1/partner-vouchers/amount-year', {
        params: data
      }).then(res => {
        this.yearLoading = false
        this.statistics.current_year = res.data.data.amount
      }).catch(err => {
        this.yearLoading = false
        console.log(err)
      })
    },
  },
  computed: {
    months() {
      return [
        {name: 'Janvier', value: 1},
        {name: 'Février', value: 2},
        {name: 'Mars', value: 3},
        {name: 'Avril', value: 4},
        {name: 'Mai', value: 5},
        {name: 'Juin', value: 6},
        {name: 'Juillet', value: 7},
        {name: 'Août', value: 8},
        {name: 'Septembre', value: 9},
        {name: 'Octobre', value: 10},
        {name: 'Novombre', value: 11},
        {name: 'Décembre', value: 12},
      ]
    },
    years() {
      return [2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030]
    },
  }
}
</script>

<style scoped>

</style>